import axios, { AxiosRequestConfig } from 'axios';
import { PagedResponse } from '../types/api';
import { SavedSearch } from '../types/SavedSearch';

const URL = '/v2/sitter-searches';

class SavedSearchService {
  static getByFilter(params: any, config?: AxiosRequestConfig) {
    return axios.get(`${URL}${params}`, config);
  }

  static getSavedSearchByFilter(config?: AxiosRequestConfig): Promise<PagedResponse<SavedSearch>> {
    return axios.get(URL, config);
  }

  static delete(id: any, config?: AxiosRequestConfig) {
    return axios.delete(`${URL}/${id}`, config);
  }

  static patch(id: any, data: any, config?: AxiosRequestConfig) {
    return axios.patch(`${URL}/${id}`, data, config);
  }
}

export { SavedSearchService };
