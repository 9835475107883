import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  Grid, InputLabel, MenuItem, Select, SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';

import { ReimbursementOrderService, User, UserVoucher, UserVoucherService } from 'us-web-services';
import toast from 'react-hot-toast';
import DisplayService from '../../../util/DisplayService';
import PageStyles from '../../../styles/PageStyles';

interface Props {
  user: User;
  history: {
    push(location: string): void;
  };
}

export default function AddReimbursement(props: Props) {
  const { history, user } = props;
  const { classes } = PageStyles();
  const [amount, setAmount] = useState(0);
  const [bookingId, setBookingId] = useState(null);
  const [userVouchers, setUserVouchers] = useState<UserVoucher[]>([]);
  const [selectedVouchers, setSelectedVouchers] = useState<number[]>([]);

  const updateAmount = event => {
    setAmount(event.target.value);
  };

  const updateBookingId = event => {
    setBookingId(event.target.value);
  };

  const onVoucherSelect = (event: SelectChangeEvent<number[]>) => {
    setSelectedVouchers(event.target.value as number[]);
  };

  const showError = (error, defaultMessage) => {
    const displayedError = DisplayService.getErrorResponse(
      error,
      defaultMessage,
    );

    toast.error(displayedError.message);
  };

  const reimburse = async () => {
    if (!amount) {
      toast.error('Amount must be greater than 0');
    } else if (!selectedVouchers || selectedVouchers.length === 0) {
      toast.error('At least one user voucher must be selected');
    } else {
      const itemVouchers = [];

      selectedVouchers.forEach(id => itemVouchers.push({ userVoucherId: id }));

      const data = {
        userId: user.id,
        total: amount,
        itemVouchers,
        itemBooking: {
          bookingId,
        },
      };

      try {
        await ReimbursementOrderService.reimburse(data);
        toast.success('Reimbursement succeeded');
        history.push(`/users/lookup?id=${user.id}`);
      } catch (error) {
        showError(error, 'Reimbursement failed');
      }
    }
  };

  const getVouchers = useCallback(async () => {
    const voucherFilter = {
      params: {
        userId: user.id,
        active: true,
        limit: 100,
      },
    };

    try {
      const vouchersResponse = (await UserVoucherService.getByFilter(voucherFilter)).data;

      setUserVouchers(vouchersResponse.data);
    } catch (error) {
      showError(error, 'Vouchers could not be loaded.');
      setUserVouchers([]);
    }
  }, [user.id]);

  useEffect(() => {
    getVouchers();
  }, [getVouchers]);

  return (
    <div style={{ padding: 10 }}>
      <Typography variant='h6' gutterBottom>
        Reimburse {user.firstName} {user.lastName} (UID: {user.id})
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormControl sx={{ minWidth: 300 }}>
            <InputLabel id='voucher-select-placeholder'>Select Vouchers</InputLabel>
            <Select
              id='voucher-select'
              value={selectedVouchers}
              onChange={onVoucherSelect}
              multiple
            >
              {userVouchers.map(item => (
                <MenuItem value={item.id} key={item.id}>
                  {item.voucher.code} - ${item.currentValue}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id='amount'
            label='Amount to Deduct'
            fullWidth
            value={amount}
            onChange={updateAmount}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id='bookingId'
            label='Booking Id'
            fullWidth
            value={bookingId}
            onChange={updateBookingId}
          />
        </Grid>
      </Grid>
      <div className={classes.buttons}>
        <Button
          variant='contained'
          color='primary'
          onClick={reimburse}
          className={classes.button}
        >
          Reimburse
        </Button>
        <br />
      </div>
    </div>
  );
}
